// Modules exported here will be included in bundle.js
export class Header
{
    constructor()
    {
        if (window.innerWidth > 992) {
            this.initHeaderDesktop();
        } else {
            this.initHeaderMobile();
        }
    }

    initHeaderDesktop() {
        const headerLinks = document.querySelectorAll('.YxtHeader-link');
        const dropdownMenus = document.querySelectorAll('.YxtHeader-hoverMenu');
        headerLinks.forEach((link) => {
            const dropdownMenuId = link.getAttribute('data-menuId');
            if (dropdownMenuId) {
                link.addEventListener('mouseover', () => {
                    dropdownMenus.forEach((menu) => {
                        if (menu.id == dropdownMenuId) {
                            menu.classList.remove('hidden');
                        } else {
                            menu.classList.add('hidden');
                        }
                    })
                })
            }
        })

        //close dropdown when user hovers onto rest of page or the top header
        const overlay = document.querySelector('.Answers');
        if (overlay) {
            overlay.addEventListener('mouseover', () => {
                dropdownMenus.forEach((menu) => {
                    menu.classList.add('hidden');
                })
            })
        }
        const topHeader = document.querySelector('.YxtHeader-top');
        if (topHeader) {
            topHeader.addEventListener('mouseover', () => {
                dropdownMenus.forEach((menu) => {
                    menu.classList.add('hidden');
                })
            })
        }
    }

    initHeaderMobile() {
        const dropdownWrappers = document.querySelectorAll('.YxtHeader-dropdownWrapper');
        dropdownWrappers.forEach((wrapper) => {
            const link = wrapper.querySelector('.YxtHeader-link--dropdown');
            if (link) {
                link.href = "#";
                link.addEventListener('click', () => {
                    dropdownWrappers.forEach((otherWrapper) => {
                        const wrappedLinks = otherWrapper.querySelector('.YxtHeader-mobileDropdownLinks');
                        const arrow = otherWrapper.querySelector('.YxtHeader-linkArrow');
                        if (otherWrapper == wrapper && wrappedLinks) {
                            wrappedLinks.classList.remove('hidden');
                            if (arrow) {
                                arrow.style.transform = 'rotate(90deg)'
                            }
                        } else if (wrappedLinks) {
                            wrappedLinks.classList.add('hidden')
                            if (arrow) {
                                arrow.style.transform = 'rotate(0deg)'
                            }
                        }
                    })
                })
            }
        })
    }
}